.root {
  background-color: var(--mantine-color-d-5);
  font-family: 'Raleway', Raleway, sans-serif;
  border: 1px solid var(--mantine-color-d-6);
  -webkit-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  padding: 14px 14px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--mantine-color-l-2);
  cursor: pointer;
  @media (max-width: 700px) {
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  gap: 10px;
}

.highlight {
  background-color: var(--mantine-color-d-1);
  font-family: 'Raleway', Raleway, sans-serif;
  border: 1px solid var(--mantine-color-d-6);
  -webkit-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  padding: 14px 14px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--mantine-color-l-2);
  cursor: pointer;
  animation: color-change-2x 0.8s linear infinite alternate both;
  @media (max-width: 700px) {
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  gap: 10px;
}

.root:hover,
.small:hover {
  color: var(--mantine-color-l-1);
  background-color: var(--mantine-color-d-0);
  -webkit-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
}

.root:active {
  color: var(--mantine-color-l-1);
  background-color: var(--mantine-color-d-1);
  -webkit-box-shadow: 4px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 4px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 4px 0px 0px -2px rgba(24, 23, 23, 0.15);
}

@-webkit-keyframes color-change-2x {
  0% {
    background: var(--mantine-color-d-1);
  }
  100% {
    background: var(--mantine-color-d-0);
  }
}
@keyframes color-change-2x {
  0% {
    background: var(--mantine-color-d-1);
  }
  100% {
    background: var(--mantine-color-d-0);
  }
}

.highlight:hover {
  -webkit-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  animation: color-change-2x 0.2s linear infinite alternate both;
}

.small {
  background-color: var(--mantine-color-d-5);
  font-family: 'Raleway', Raleway, sans-serif;
  border: 1px solid var(--mantine-color-d-6);
  -webkit-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  padding: 14px 14px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--mantine-color-l-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
