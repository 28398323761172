.container {
  padding: 0px 20px;
  margin-right: 20px;
  border: 1px solid var(--mantine-color-d-4);
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
  @media (max-width: 700px) {
    gap: 20px;
  }
}
.item {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    max-width: 32px;
  }
  p {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    color: var(--mantine-color-l-3);
  }
}

.icon {
  padding: 5px;
  border: 1px solid var(--mantine-color-l-5);
  background-color: transparent;
  cursor: pointer;
}
.icon:hover {
  padding: 5px;
  border: 1px solid var(--mantine-color-l-5);
  background-color: var(--mantine-color-d-7);
  cursor: pointer;
}
