.mainTable {
  background-color: var(--mantine-color-d-6);
  margin-bottom: 40px;
  th {
    padding: 20px 10px;
    text-align: center;
    color: var(--mantine-color-l-2);
    line-height: 1;
    font-family: 'Raleway', Raleway, sans-serif;
    font-weight: 500;
    font-size: 18px;
  }
  tr {
    border-bottom: 0px;
  }
  td {
    padding: 10px 5px;
    text-align: center;
    color: var(--mantine-color-l-4);
    line-height: 1;
    font-family: 'Raleway', Raleway, sans-serif;
    font-weight: 400;
    font-size: 16px;
    border-top: 1px solid var(--mantine-color-d-4);
    img {
      border: 1px solid var(--mantine-color-d-5);
      background-color: var(--mantine-color-l-5);
    }
  }
  @media (max-width: 1000px) {
    th {
      padding: 10px 5px;
      font-size: 16px;
    }
    td {
      padding: 5px 0px;
      font-size: 14px;
    }
  }
}
