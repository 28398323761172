.content {
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  span {
    color: var(--mantine-color-l-5);
  }
}
.formFlex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.timer {
  color: var(--mantine-color-l-2);
  font-family: 'Bebas Neue, sans-serif';
  font-size: 40px;
  line-height: 1;
  margin-top: -20px;
}

.wrapper {
  background-color: var(--mantine-color-d-6);
  border: none;
}
.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input {
  border: 1px solid var(--mantine-color-d-8);
  background-color: var(--mantine-color-d-6);
  color: var(--mantine-color-l-2);
  font-family: 'Bebas Neue, sans-serif';
  font-size: 24px;
  line-height: 1;
}
.description {
  font-family: 'Bebas Neue, sans-serif';
  font-size: 16px;
  line-height: 1;
}
.label {
  color: var(--mantine-color-l-5);
  font-family: 'Bebas Neue, sans-serif';
  font-size: 26px;
  line-height: 1;
}
