.root {
  z-index: 1;
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: var(--mantine-color-d-7);
  -webkit-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 60px;
  }
}

.info {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--mantine-color-d-4);
  padding: 16px 20px;
  gap: 40px;
}

.infoLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  p {
    font-size: 1.1rem;
    line-height: 1;
    color: var(--mantine-color-l-4);
  }

  span {
    font-size: 1.2rem;
    line-height: 1;
    color: var(--mantine-color-l-1);
  }
}

.cta {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
