::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--mantine-color-d-4);
}
::-webkit-scrollbar-thumb {
  background: var(--mantine-color-d-1);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--mantine-color-d-0);
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  background-color: var(--mantine-color-d-8);
  background-image: url('./Assets/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.content {
  /* z-index: 0; */
  /* position: absolute; */
  /* top: 80px; */
  /* left: 150px; */
  /* width: calc(100% - 150px); */
  /* height: calc(100% - 80px); */
  flex-grow: 1;
  /* align-items: center; */
  overflow-y: auto;
  /* @media (max-width: 700px) {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - 200px);
  } */
}

.wrapperFluid {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 20px;
  -webkit-animation: scale-in-hor-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-hor-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  -webkit-animation: scale-in-hor-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-hor-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @media (max-width: 700px) {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
}

.rowBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 90px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
}
.rowItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0px;
  span {
    color: var(--mantine-color-l-7);
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    font-family: 'Raleway', Raleway, sans-serif;
    cursor: pointer;
  }
  span:hover {
    color: var(--mantine-color-l-5);
  }

  overflow: auto;
}
