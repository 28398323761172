.flexBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 0 40px;
  button {
    display: flex;
    justify-content: center;
    width: 150px;
  }
}
.flexBoxCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    width: 150px;
  }
}
