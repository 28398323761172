.info {
  display: flex;
  gap: 40px;
  align-items: center;
  @media (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column;
  }
}
.myInfo {
  display: flex;
  gap: 40px;
  align-items: center;
  padding-top: 40px;
  @media (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column;
  }
}
