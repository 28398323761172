.mainText {
  input {
    background-color: var(--mantine-color-d-6);
    border: 1px solid var(--mantine-color-d-8);
    color: var(--mantine-color-l-5);
    font-family: 'Raleway', Raleway, san-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 0;
    padding-left: 20px;
  }
}

.mainPassword {
  border: none;
  input {
    background-color: var(--mantine-color-d-6);
    border: 1px solid var(--mantine-color-d-8);
    color: var(--mantine-color-l-5);
    font-family: 'Raleway', Raleway, san-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 0;
    padding-left: 20px;
  }
}
