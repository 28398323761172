.titleWrapper {
  width: 100%;
  padding: 20px 20px 0px 20px;
}

.title {
  font-family: 'Raleway', Raleway, san-serif;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  color: var(--mantine-color-l-1);
}
