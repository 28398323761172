.content {
  width: 250px;
  height: 76px;
  background-color: var(--mantine-color-d-8);
  border: 1px solid var(--mantine-color-d-6);
  padding: 10px 10px 10px 20px;
  @media (max-width: 800px) {
    width: 220px;
  }
}
.contentLarge {
  width: 320px;
  height: 76px;
  background-color: var(--mantine-color-d-8);
  border: 1px solid var(--mantine-color-d-6);
  padding: 10px 10px 10px 20px;
  @media (max-width: 800px) {
    width: 280px;
  }
}
.highlight {
  border: 1px solid var(--mantine-color-d-0);
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: 'Bebas Neue, sans-serif';
    font-size: 20px;
    color: var(--mantine-color-l-5);
    line-height: 1;
    margin: 0;
  }
  p {
    font-family: 'Bebas Neue, sans-serif';
    font-size: 36px;
    color: var(--mantine-color-l-0);
    line-height: 1;
    margin: 0;
  }
  img {
    max-width: 32px;
    max-height: 32px;
  }
}
.itemLabel {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
