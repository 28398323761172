.wrapper {
  background-color: var(--mantine-color-d-7);
  border: 1px solid var(--mantine-color-d-6);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  h2 {
    color: var(--mantine-color-l-3);
    line-height: 1;
    font-family: 'Raleway', Raleway, san-serif;
    font-weight: 500;
    font-size: 28px;
  }
  p {
    color: var(--mantine-color-l-5);
    line-height: 1.6;
    font-family: 'Raleway', Raleway, san-serif;
    font-weight: 300;
    font-size: 22px;
  }
}
