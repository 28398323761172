.root {
  width: 150px;
  flex-shrink: 0;
  height: calc(100vh - 80px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
  background-color: var(--mantine-color-d-7);
  -webkit-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 6px 0px 0px -2px rgba(24, 23, 23, 0.15);
  z-index: 999;
  @media (max-width: 700px) {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 60px;
  }
}
