.root {
  border: 1px solid red;
  width: 125px;
  height: 125px;
  border: 1px solid var(--mantine-color-d-5);
  background-color: var(--mantine-color-d-6);
  color: var(--mantine-color-l-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  p {
    line-height: 1;
    font-size: 20px;
  }
  svg {
    path {
      transition: fill 0.3s ease;
      fill: var(--mantine-color-l-2);
    }
  }
  @media (max-width: 700px) {
    gap: 2px;
    p {
      line-height: 1;
      font-size: 12px;
    }
    width: 80px;
    height: 80px;
  }
}

.root:hover {
  color: var(--mantine-color-l-1);
  svg {
    path {
      fill: var(--mantine-color-l-1);
    }
  }
  background-color: var(--mantine-color-d-0);
  -webkit-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  -moz-box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
  box-shadow: 7px 0px 0px -2px rgba(24, 23, 23, 0.15);
}

.root:active {
  color: var(--mantine-color-d-5);
  background-color: var(--mantine-color-l-2);
  svg {
    path {
      fill: var(--mantine-color-d-5);
    }
  }
}

.active {
  color: var(--mantine-color-d-5);
  background-color: var(--mantine-color-l-2);
  svg {
    path {
      fill: var(--mantine-color-d-5);
    }
  }

  border: 1px solid red;
  width: 125px;
  height: 125px;
  border: 1px solid var(--mantine-color-d-5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: not-allowed;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  p {
    line-height: 1;
    font-size: 20px;
  }
  @media (max-width: 700px) {
    gap: 2px;
    p {
      line-height: 1;
      font-size: 12px;
    }
    width: 80px;
    height: 80px;
  }
}
